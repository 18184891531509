@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/montserrat-regular-webfont.092691b3.woff),
      url(/static/media/montserrat-regular-webfont.092691b3.woff) format('woff'),
      url(/static/media/montserrat-regular-webfont.7d9f7255.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/montserrat-medium-webfont.9f987f99.woff),
      url(/static/media/montserrat-medium-webfont.9f987f99.woff) format('woff'),
      url(/static/media/montserrat-medium-webfont.0f24c6dc.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/montserrat-semibold-webfont.5ff1fc98.woff),
      url(/static/media/montserrat-semibold-webfont.5ff1fc98.woff) format('woff'),
      url(/static/media/montserrat-semibold-webfont.72964db1.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/montserrat-bold-webfont.6b66574d.woff),
      url(/static/media/montserrat-bold-webfont.6b66574d.woff) format('woff'),
      url(/static/media/montserrat-bold-webfont.b0d2b498.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url(/static/media/montserrat-extrabold-webfont.5e8a4446.woff),
      url(/static/media/montserrat-extrabold-webfont.5e8a4446.woff) format('woff'),
      url(/static/media/montserrat-extrabold-webfont.d44d432c.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url(/static/media/DINPro.eed65605.woff),
      url(/static/media/DINPro.eed65605.woff) format('woff'),
      url(/static/media/DINPro.7b17b328.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url(/static/media/DINPro-Medium.fae86e25.woff),
      url(/static/media/DINPro-Medium.fae86e25.woff) format('woff'),
      url(/static/media/DINPro-Medium.339adcee.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url(/static/media/DINPro-Medium.fae86e25.woff),
      url(/static/media/DINPro-Medium.fae86e25.woff) format('woff'),
      url(/static/media/DINPro-Medium.339adcee.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url(/static/media/DINPro-Bold.9deac32b.woff),
      url(/static/media/DINPro-Bold.9deac32b.woff) format('woff'),
      url(/static/media/DINPro-Bold.641c2fc9.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url(/static/media/DINPro-Bold.9deac32b.woff),
      url(/static/media/DINPro-Bold.9deac32b.woff) format('woff'),
      url(/static/media/DINPro-Bold.641c2fc9.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
}
.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
  touch-action: auto;
  -ms-touch-action: auto;
}

.slick-list {
  overflow: hidden;
}

.slick-list:after {
  clear: both;
  content: '';
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide.slick-active {
  visibility: visible;
}

.slick-slider {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 300px;
  width: 60px;
  height: 100px;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
}

@media screen and (max-width: 812px) {
  .slick-next,
  .slick-prev {
    top: 80px;
  }
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}

.slick-prev {
  left: -65px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='27' height='50' viewBox='0 0 27 50'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h27v50H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='matrix(1 0 0 -1 0 50)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%23FFF' d='M3.499 24.999L26.574 2.418a1.39 1.39 0 0 0 0-2.001 1.464 1.464 0 0 0-2.045 0L.426 24.003a1.39 1.39 0 0 0 0 2.002L24.529 49.58c.279.272.653.419 1.017.419.364 0 .74-.136 1.018-.42a1.39 1.39 0 0 0 0-2L3.499 24.999z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: 27px;
  background-repeat: no-repeat;
  background-position: 10px center;
}

@media screen and (max-width: 812px) {
  .slick-prev {
    background-size: 22px;
  }
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-next {
  right: -65px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='27' height='50' viewBox='0 0 27 50'%3E%3Cdefs%3E%3Cpath id='a' d='M0 0h27v50H0z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd' transform='rotate(180 13.5 25)'%3E%3Cmask id='b' fill='%23fff'%3E%3Cuse xlink:href='%23a'/%3E%3C/mask%3E%3Cpath fill='%23FFF' d='M3.499 24.999L26.574 2.418a1.39 1.39 0 0 0 0-2.001 1.464 1.464 0 0 0-2.045 0L.426 24.003a1.39 1.39 0 0 0 0 2.002L24.529 49.58c.279.272.653.419 1.017.419.364 0 .74-.136 1.018-.42a1.39 1.39 0 0 0 0-2L3.499 24.999z' mask='url(%23b)'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-size: 27px;
  background-repeat: no-repeat;
  background-position: 23px center;
}

@media screen and (max-width: 812px) {
  .slick-next {
    background-size: 22px;
  }
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '<';
  text-align: center;
  opacity: 0.25;
  color: #000;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

.slick-slide img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transition: opacity 0.3s ease 0.4s, -webkit-transform 0.3s ease 0.4s, -webkit-filter 0.3s ease;
  transition: opacity 0.3s ease 0.4s, -webkit-transform 0.3s ease 0.4s, -webkit-filter 0.3s ease;
  transition: opacity 0.3s ease 0.4s, transform 0.3s ease 0.4s, filter 0.3s ease;
  transition: opacity 0.3s ease 0.4s, transform 0.3s ease 0.4s, filter 0.3s ease, -webkit-transform 0.3s ease 0.4s, -webkit-filter 0.3s ease;
  opacity: 0.3;
  will-change: filter, opacity;
}

.slick-current img {
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  -o-filter: grayscale(0);
  filter: grayscale(0);
  -webkit-filter: none;
  filter: none;
}

