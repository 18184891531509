@font-face {
  font-family: 'Montserrat';
  src: url('./montserrat-regular-webfont.woff'),
      url('./montserrat-regular-webfont.woff') format('woff'),
      url('./montserrat-regular-webfont.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./montserrat-medium-webfont.woff'),
      url('./montserrat-medium-webfont.woff') format('woff'),
      url('./montserrat-medium-webfont.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./montserrat-semibold-webfont.woff'),
      url('./montserrat-semibold-webfont.woff') format('woff'),
      url('./montserrat-semibold-webfont.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./montserrat-bold-webfont.woff'),
      url('./montserrat-bold-webfont.woff') format('woff'),
      url('./montserrat-bold-webfont.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('./montserrat-extrabold-webfont.woff'),
      url('./montserrat-extrabold-webfont.woff') format('woff'),
      url('./montserrat-extrabold-webfont.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('./DINPro.woff'),
      url('./DINPro.woff') format('woff'),
      url('./DINPro.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('./DINPro-Medium.woff'),
      url('./DINPro-Medium.woff') format('woff'),
      url('./DINPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('./DINPro-Medium.woff'),
      url('./DINPro-Medium.woff') format('woff'),
      url('./DINPro-Medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('./DINPro-Bold.woff'),
      url('./DINPro-Bold.woff') format('woff'),
      url('./DINPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'DINPro';
  src: url('./DINPro-Bold.woff'),
      url('./DINPro-Bold.woff') format('woff'),
      url('./DINPro-Bold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}